import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageKeysEnum } from '@app/domain/models/storage-keys.enum';
import { HttpAbstractService } from '@app/domain/services/http-abstract.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { JwtTokenModel } from '../models/jwt-token.model';
import { SignInModel } from '../models/sign-in.model';
import { SignInResponseModel } from '../models/sign-in.response';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends HttpAbstractService {
    baseUrl = `${this.baseUrl}/auth`;
    private tokenData$ = new BehaviorSubject<JwtTokenModel | null>(this.getDecodedTokenFromLocalStorage());
    private token$ = new BehaviorSubject<string>(this.localStorageService.getItem(StorageKeysEnum.JWT_TOKEN));

    constructor(private http: HttpClient, private localStorageService: LocalStorageService, private router: Router) {
        super();
    }

    signIn(credentials: SignInModel): Observable<SignInResponseModel> {
        return this.http.post<SignInResponseModel>(`${this.baseUrl}/signin`, credentials);
    }

    signUp(credentials: SignInModel): Observable<any> {
        return this.http.post(`${this.baseUrl}/signup`, credentials);
    }

    logout(): void {
        this.localStorageService.clearItem(StorageKeysEnum.JWT_TOKEN);
        this.token$.next(undefined);
        this.tokenData$.next(undefined);
        this.router.navigate(['login']);
    }

    observeUserData(): Observable<JwtTokenModel | null> {
        return this.tokenData$.asObservable();
    }

    getToken(): string {
        return this.token$.value;
    }

    getTokenData(): JwtTokenModel | null {
        return this.tokenData$.value;
    }

    handleToken(token: any): void {
        this.localStorageService.setItem(StorageKeysEnum.JWT_TOKEN, token);
        this.tokenData$.next(this.decodeToken(token));
        this.token$.next(token);
    }

    private getDecodedTokenFromLocalStorage(): JwtTokenModel | null {
        const token = this.localStorageService.getItem(StorageKeysEnum.JWT_TOKEN);

        return token ? this.decodeToken(this.localStorageService.getItem(StorageKeysEnum.JWT_TOKEN)) : null;
    }

    private decodeToken(token: string): JwtTokenModel {
        return JSON.parse(atob(token.split('.')[1]));
    }

    private refreshToken(): void {}
}
