import { Injectable } from '@angular/core';

import { StorageKeysEnum } from '../models/storage-keys.enum';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    setItem(key: StorageKeysEnum, data: any): void {
        localStorage.setItem(key, JSON.stringify(data));
    }

    getItem(key: StorageKeysEnum): any {
        return JSON.parse(localStorage.getItem(key));
    }

    clearItem(key: StorageKeysEnum): void {
        if (localStorage.getItem(key) !== null) {
            localStorage.removeItem(key);
        }
    }

    clearAllItems(): void {
        localStorage.clear();
    }
}
