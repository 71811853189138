import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import 'reflect-metadata';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { ErrorInterceptor } from './domain/interceptors/error.interceptor';
import { JwtTokenInterceptor } from './domain/interceptors/jwt-token.interceptor';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        RouterModule.forRoot(APP_ROUTES, { relativeLinkResolution: 'legacy' }),
        BrowserAnimationsModule,
        HttpClientModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtTokenInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
