import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/domain/services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private authService: AuthService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError(err => {
                switch (err.status) {
                    case 401:
                        this.handleUnathorized();
                        break;
                    case 500:
                        this.navigateToErrorPage();
                        break;
                    default:
                        break;
                }

                return throwError(err);
            })
        );
    }

    private handleUnathorized(): void {
        this.authService.logout();
        this.router.navigate(['login']);
    }

    private navigateToErrorPage(): void {
        // TODO handle error page
        this.router.navigate(['error']);
    }
}
