import { Routes } from '@angular/router';

import { AuthGuard } from './shared/modules/auth/guards/auth.guard';
import { LoginGuard } from './shared/modules/auth/guards/login.guard';

export const APP_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('./views/main/main.module').then(m => m.MainModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule),
        canActivate: [LoginGuard],
    },
    { path: 'error', loadChildren: () => import('./views/error/error.module').then(m => m.ErrorModule) },
];
